<template>
  <div>
    <!-- 左上 -->
    <div class="picfx picfx_lt" v-show="PlacementCloseId.indexOf(2) < 0 && Images_2.length > 0">
      <div @click="CloseImage(2)" class="picfx_close">X</div>
      <template v-for="item in Images_2">
        <template v-if="!item.has_url">
          <img :src="item.image" :alt="item.name" :title="item.name">
        </template>
        <template v-else-if="item.has_protocol || item.has_blank">
          <a :href="item.url" :target="item.blank">
            <img :src="item.image" :alt="item.name" :title="item.name">
          </a>
        </template>
        <template v-else>
          <nuxt-link :to="item.url">
            <img :src="item.image" :alt="item.name" :title="item.name">
          </nuxt-link>
        </template>
      </template>
    </div>

    <!-- 左中 -->
    <div class="picfx picfx_lc" v-show="PlacementCloseId.indexOf(3) < 0 && Images_3.length > 0">
      <div @click="CloseImage(3)" class="picfx_close">X</div>
      <template v-for="item in Images_3">
        <template v-if="!item.has_url">
          <img :src="item.image" :alt="item.name" :title="item.name">
        </template>
        <template v-else-if="item.has_protocol || item.has_blank">
          <a :href="item.url" :target="item.blank">
            <img :src="item.image" :alt="item.name" :title="item.name">
          </a>
        </template>
        <template v-else>
          <nuxt-link :to="item.url">
            <img :src="item.image" :alt="item.name" :title="item.name">
          </nuxt-link>
        </template>
      </template>
    </div>

    <!-- 左下 -->
    <div class="picfx picfx_lb" v-show="PlacementCloseId.indexOf(4) < 0 && Images_4.length > 0">
      <div @click="CloseImage(4)" class="picfx_close">X</div>
      <template v-for="item in Images_4">
        <template v-if="!item.has_url">
          <img :src="item.image" :alt="item.name" :title="item.name">
        </template>
        <template v-else-if="item.has_protocol || item.has_blank">
          <a :href="item.url" :target="item.blank">
            <img :src="item.image" :alt="item.name" :title="item.name">
          </a>
        </template>
        <template v-else>
          <nuxt-link :to="item.url">
            <img :src="item.image" :alt="item.name" :title="item.name">
          </nuxt-link>
        </template>
      </template>
    </div>

    <!-- 右上 -->
    <div class="picfx picfx_rt" v-show="PlacementCloseId.indexOf(5) < 0 && Images_5.length > 0">
      <div @click="CloseImage(5)" class="picfx_close">X</div>
      <template v-for="item in Images_5">
        <template v-if="!item.has_url">
          <img :src="item.image" :alt="item.name" :title="item.name">
        </template>
        <template v-else-if="item.has_protocol || item.has_blank">
          <a :href="item.url" :target="item.blank">
            <img :src="item.image" :alt="item.name" :title="item.name">
          </a>
        </template>
        <template v-else>
          <nuxt-link :to="item.url">
            <img :src="item.image" :alt="item.name" :title="item.name">
          </nuxt-link>
        </template>
      </template>
    </div>

    <!-- 右中 -->
    <div class="picfx picfx_rc" v-show="PlacementCloseId.indexOf(6) < 0 && Images_6.length > 0">
      <div @click="CloseImage(6)" class="picfx_close">X</div>
      <template v-for="item in Images_6">
        <template v-if="!item.has_url">
          <img :src="item.image" :alt="item.name" :title="item.name">
        </template>
        <template v-else-if="item.has_protocol || item.has_blank">
          <a :href="item.url" :target="item.blank">
            <img :src="item.image" :alt="item.name" :title="item.name">
          </a>
        </template>
        <template v-else>
          <nuxt-link :to="item.url">
            <img :src="item.image" :alt="item.name" :title="item.name">
          </nuxt-link>
        </template>
      </template>
    </div>

    <!-- 右下 -->
    <div class="picfx picfx_rb" v-show="PlacementCloseId.indexOf(7) < 0 && Images_7.length > 0">
       <div @click="CloseImage(7)" class="picfx_close">X</div>
      <template v-for="item in Images_7">
        <template v-if="!item.has_url">
          <img :src="item.image" :alt="item.name" :title="item.name">
        </template>
        <template v-else-if="item.has_protocol || item.has_blank">
          <a :href="item.url" :target="item.blank">
            <img :src="item.image" :alt="item.name" :title="item.name">
          </a>
        </template>
        <template v-else>
          <nuxt-link :to="item.url">
            <img :src="item.image" :alt="item.name" :title="item.name">
          </nuxt-link>
        </template>
      </template>
    </div>

    <b-modal
      id="modalImage"
      ref="modalImage"
      centered
      :modal-class="ModalImageClass"
      hide-footer
      hide-header
    >
      <button @click="HideModal" class="picfx_close">
        <i class="closeIcon"></i>
      </button>

      <div class="modal-main">
        <b-carousel
          :interval="6000"
          controls
          indicators
          @sliding-start="OnSlideStart"
          @sliding-end="OnSlideEnd"
        >
          <b-carousel-slide v-for="(item, index) in Images_1" :key="index">
            <template #img>
              <template v-if="!item.has_url">
                <img v-lazy="item.image" :alt="item.name" :title="item.name">
              </template>
              <template v-else-if="item.has_protocol || item.has_blank">
                <a :href="item.url" :target="item.blank">
                  <img v-lazy="item.image" :alt="item.name" :title="item.name">
                </a>
              </template>
              <template v-else>
                <nuxt-link :to="item.url">
                  <img v-lazy="item.image" :alt="item.name" :title="item.name">
                </nuxt-link>
              </template>
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapGetters } from "vuex";
  export default {
    props: {
      device: { type: String, default: '' }
    },
    methods: {
      ShowModal: function () {
        this.$refs.modalImage.show();
      },
      HideModal() {
        this.$refs.modalImage.hide();
      },
      OnSlideStart(slide) {
        this.sliding = true;
      },
      OnSlideEnd(slide) {
        this.sliding = false;
      },
      CloseImage(id) {
        this.setPlacementCloseId(id);
      },
      ...mapMutations('placement', ['setPlacementCloseId', 'setPlacementShow']),
    },
    mounted: function () {
      if (this.PlacementShow && this.Images_1.length > 0) {
        this.ShowModal();
        this.setPlacementShow(false);
      }
    },
    computed: {
      ModalImageClass() {
        return ["dialog-forgot","picfx_modal"];
      },
      Images_1() {
        return this.PlacementList.filter((Item) => {
          return Item.type == 1 && this.PlacementCloseId.indexOf(Item.type) < 0 && ((this.device == 'desktop' && Item.pc_show) || (this.device == 'mobile' && Item.mobile_show));
        }).map((Item) => {
          return {
            image: this.device == 'desktop' ? Item.pc_image : Item.mobile_image,
            id: Item.id,
            name: Item.name,
            url: Item.url,
            blank: Item.blank,
            has_blank: Item.has_blank,
            has_protocol: Item.has_protocol,
            has_url: Item.has_url
          };
        });
      },
      Images_2() {
        return this.PlacementList.filter((Item) => {
          return Item.type == 2 && this.PlacementCloseId.indexOf(Item.type) < 0 && ((this.device == 'desktop' && Item.pc_show) || (this.device == 'mobile' && Item.mobile_show));
        }).map((Item) => {
          return {
            image: this.device == 'desktop' ? Item.pc_image : Item.mobile_image,
            id: Item.id,
            name: Item.name,
            url: Item.url,
            blank: Item.blank,
            has_blank: Item.has_blank,
            has_protocol: Item.has_protocol,
            has_url: Item.has_url
          };
        });
      },
      Images_3() {
        return this.PlacementList.filter((Item) => {
          return Item.type == 3 && this.PlacementCloseId.indexOf(Item.type) < 0 && ((this.device == 'desktop' && Item.pc_show) || (this.device == 'mobile' && Item.mobile_show));
        }).map((Item) => {
          return {
            image: this.device == 'desktop' ? Item.pc_image : Item.mobile_image,
            id: Item.id,
            name: Item.name,
            url: Item.url,
            blank: Item.blank,
            has_blank: Item.has_blank,
            has_protocol: Item.has_protocol,
            has_url: Item.has_url
          };
        });
      },
      Images_4() {
        return this.PlacementList.filter((Item) => {
          return Item.type == 4 && this.PlacementCloseId.indexOf(Item.type) < 0 && ((this.device == 'desktop' && Item.pc_show) || (this.device == 'mobile' && Item.mobile_show));
        }).map((Item) => {
          return {
            image: this.device == 'desktop' ? Item.pc_image : Item.mobile_image,
            id: Item.id,
            name: Item.name,
            url: Item.url,
            blank: Item.blank,
            has_blank: Item.has_blank,
            has_protocol: Item.has_protocol,
            has_url: Item.has_url
          };
        });
      },
      Images_5() {
        return this.PlacementList.filter((Item) => {
          return Item.type == 5 && this.PlacementCloseId.indexOf(Item.type) < 0 && ((this.device == 'desktop' && Item.pc_show) || (this.device == 'mobile' && Item.mobile_show));
        }).map((Item) => {
          return {
            image: this.device == 'desktop' ? Item.pc_image : Item.mobile_image,
            id: Item.id,
            name: Item.name,
            url: Item.url,
            blank: Item.blank,
            has_blank: Item.has_blank,
            has_protocol: Item.has_protocol,
            has_url: Item.has_url
          };
        });
      },
      Images_6() {
        return this.PlacementList.filter((Item) => {
          return Item.type == 6 && this.PlacementCloseId.indexOf(Item.type) < 0 && ((this.device == 'desktop' && Item.pc_show) || (this.device == 'mobile' && Item.mobile_show));
        }).map((Item) => {
          return {
            image: this.device == 'desktop' ? Item.pc_image : Item.mobile_image,
            id: Item.id,
            name: Item.name,
            url: Item.url,
            blank: Item.blank,
            has_blank: Item.has_blank,
            has_protocol: Item.has_protocol,
            has_url: Item.has_url
          };
        });
      },
      Images_7() {
        return this.PlacementList.filter((Item) => {
          return Item.type == 7 && this.PlacementCloseId.indexOf(Item.type) < 0 && ((this.device == 'desktop' && Item.pc_show) || (this.device == 'mobile' && Item.mobile_show));
        }).map((Item) => {
          return {
            image: this.device == 'desktop' ? Item.pc_image : Item.mobile_image,
            id: Item.id,
            name: Item.name,
            url: Item.url,
            blank: Item.blank,
            has_blank: Item.has_blank,
            has_protocol: Item.has_protocol,
            has_url: Item.has_url
          };
        });
      },
      ...mapState('placement', ['PlacementList', 'PlacementCloseId', 'PlacementShow'])
    }
  };
</script>
